import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import PageBackground from "../components/atoms/page-background"
import ImageGalleryPage from "../components/organisms/gallery-page"
import { graphql } from "gatsby"

const GalleryPageWrapper = ({ data }) => {
  const images = data.gallery.edges.map(({ node }) => node.childImageSharp)
  return (
    <Layout
      cert1={data.cert1.childImageSharp.fluid}
      cert2={data.cert2.childImageSharp.fluid}
    >
      <SEO title="Galeria zdjęć" />
      <PageBackground img={data.placeholderImage.childImageSharp.fluid} />
      <ImageGalleryPage images={images} />
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "library.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default GalleryPageWrapper
