import React from "react"
import PropTypes from "prop-types"
import Gallery from "@browniebroke/gatsby-image-gallery"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"

const ImageGalleryPage = ({ images }) => (
  <Wrapper>
    <ParagraphItalic>galeria-zdjęć</ParagraphItalic>
    <Heding>Zobacz jak u nas jest</Heding>
    <br />
    <br />
    <Gallery images={images} />
  </Wrapper>
)

ImageGalleryPage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      caption: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
}

export default ImageGalleryPage
